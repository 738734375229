<template>
  <div class="box">
    <div class="topTitle" id="shou">
      <div class="head">
        <div class="left">
          <img class="icon" src="../assets/320潘达LOGO@2x.png" alt="">
          <h1>潘达潮玩</h1>
        </div>
        <div class="right">
          <div class="textItem avater"><a class="ato" href="#shou">首页</a></div>
          <div class="textItem"><a class="ato" href="#guanyu">关于我们</a></div>
          <div class="textItem"><a class="ato" href="#lianxi">联系我们</a></div>
        </div>
      </div>
      <div class="img">
        <img src="../assets/pandabanner@2x.png" alt="">
      </div>
    </div>
    <div class="conte">
      <div id="guanyu" class="guanyu">
        <div class="left">
          <div class="title">关于我们</div>
          <div class="mes">About Us</div>
          <div class="kuai"></div>
          <div class="con">潘达潮玩是一家致力于成为中国一线潮玩品牌的公司，成立于2021年，以兴趣潮
            流电商为起点，以热忧服务为中心，并围绕艺术家挖掘、IP孵化运营、消费者触
            达、潮玩文化推广等领域，构建了覆盖潮流玩具全产业链的综合运营平台。</div>
          <div class="con">潘达潮玩始终坚持做一个有温度的公司，以“引领潮流·传递美好”为使命，积极
            做一个正能量的潮流文化品牌。</div>
        </div>
        <div class="right">
          <img src="../assets/图像70@2x.png" alt="">
        </div>
      </div>
      <div class="fanchou">
        <div class="title">业务范畴</div>
        <div class="mes">Business Scope</div>
        <div class="kuai"></div>
        <div class="conList">
          <div class="item">
            <div class="icons">
              <img src="../assets/图像92@2x.png" alt="">
              <div class="iconText">进口轻奢万代模型</div>
            </div>
            <div class="text">潘达潮玩主营业务以进口轻奢万代模型和
              国模产品为主，目前模型手办产品库存量
              湖北第一，抖音模型玩具榜单排行第一。</div>

          </div>
          <div class="item">
            <div class="icons">
              <img src="../assets/图像90@2x.png" alt="">
              <div class="iconText">潮玩IP自主研发</div>
            </div>
            <div class="text">潘达潮玩正在研发自主潮玩品牌，并已成
              功研发出第一款自研潮玩品牌---PANDA
              后续将进行更多自有品牌的研发与生产。</div>

          </div>
          <div class="item">
            <div class="icons">
              <img src="../assets/图像91@2x.png" alt="">
              <div class="iconText">潮流画作及手办</div>
            </div>
            <div class="text">公司业务实现多元化拓展涉及到潮流画作
              及潮流大体手办如熊砖村上隆、高桥亚树
              等潮流作品。</div>


          </div>
        </div>
      </div>
      <div class="pinpai">
        <div class="title">品牌历程</div>
        <div class="mes">Brand History</div>
        <div class="kuai"></div>
        <div class="coBox">
          <div class="left">
            <img src="../assets//蒙版组72@2x.png" alt="">
          </div>
          <div class="right">
            <div class="item"><span class="kuai"></span><span class="time">2021年02月</span>PANDAPLAY品牌正式创立</div>
            <div class="item"><span class="kuai"></span><span class="time">2021年05月</span>潘达潮玩(武汉)文化发展有限公司正式开业</div>
            <div class="item"><span class="kuai"></span><span class="time">2022年08月</span>潘达潮玩第一款自研IPPANDA诞生</div>
            <div class="item"><span class="kuai"></span><span class="time">2022年11月</span>潘达潮玩实现规模再扩大，开启新阶段</div>
          </div>
        </div>
      </div>
      <div class="daili">
        <div class="title">代理产品</div>
        <div class="mes">Agent Products</div>
        <div class="kuai"></div>
        <div class="coBox">
          <div class="left">
            <div><img src="../assets/图像81@2x.png" alt=""></div>
            <div><img src="../assets/图像82@2x.png" alt=""></div>
          </div>
          <div class="con">
            <div class="top"><img src="../assets/图像85@2x.png" alt=""></div>
            <div class="bom"><img src="../assets/组403@2x.png" alt=""></div>
          </div>
          <div class="right">
            <div><img src="../assets/图像84@2x.png" alt=""></div>
            <div><img src="../assets/图像83@2x.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <div id="lianxi" class="end">
      <div>公司全称：潘达潮玩（武汉）文化发展有限公司</div>
      <div>公司邮箱：pandaplay20210520@163.com / pandaplayapp@163.com</div>
      <div>公司地址：孝感市临空区孝汉大道湖北交投天河智谷产业园7号楼</div>
      <div><a class="a" href="https://beian.miit.gov.cn">Copyright ©2017-2021 鄂ICP备2021015549号-1</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

<style scoped lang="scss">
.ato{
  text-decoration: none;
  color: #FFFFFF;
}
.a {
  text-decoration: none;
  color: #FFFFFF;
}

.box {
  width: 100%;
  min-height: 100vh;
  background-color: #FFFFFF;

  .topTitle {
    width: 100%;
    background-color: #2D2D2D;

    .head {
      height: 60px;
      padding: 0 7% 0 10%;
      display: flex;
      color: #FFFFFF;
      align-items: center;

      .left {
        width: 50%;
        display: flex;
        align-items: center;

        h1 {
          margin-left: 15px;
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }

      .right {
        width: 50%;
        font-size: 18px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .textItem {
          margin-left: 80px;
        }

        .avater {
          font-weight: 700;
        }
      }
    }

    .img {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .conte {
    padding: 0 10%;

    .guanyu {
      width: 100%;
      display: flex;
      padding-top: 110px;

      .left {
        width: 45%;
        height: 376px;
        text-align: left;

        .title {
          font-size: 48px;
          font-family: 'Microsoft YaHei';
          font-weight: bold;
          color: #E31616;
        }

        .mes {
          font-size: 22px;
          font-family: Arial;
          font-weight: 400;
        }

        .kuai {
          width: 80px;
          height: 6px;
          background-color: #E31616;
          margin-top: 10px;
        }

        .con {
          margin-top: 50px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #232B39;
        }
      }

      .right {
        width: 50%;
        height: 376px;
        text-align: right;

        img {
          width: 565px;
          height: 100%;
        }
      }
    }

    .fanchou {
      padding-top: 120px;

      .title {
        font-size: 48px;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #E31616;
      }

      .mes {
        font-size: 22px;
        font-family: Arial;
        font-weight: 400;
      }

      .kuai {
        width: 80px;
        height: 6px;
        background-color: #E31616;
        margin-top: 10px;
      }

      .conList {
        margin-top: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          width: 345px;

          .icons {
            width: 345px;
            height: 325px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .iconText {
              width: 100%;
              height: 48px;
              background-color: rgba($color: #000000, $alpha: .46);
              position: absolute;
              bottom: 0;
              left: 0;
              box-sizing: border-box;
              padding-left: 20px;
              color: #FFFFFF;
              line-height: 48px;
              font-weight: bold;
              font-size: 18px;

            }
          }
        }
      }

    }

    .pinpai {
      padding-top: 120px;

      .title {
        font-size: 48px;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #E31616;
      }

      .mes {
        font-size: 22px;
        font-family: Arial;
        font-weight: 400;
      }

      .kuai {
        width: 80px;
        height: 6px;
        background-color: #E31616;
        margin-top: 10px;
      }

      .coBox {
        margin-top: 60px;
        display: flex;
        align-items: center;

        .left {
          img {
            width: 670px;
            height: 393px;
          }
        }

        .right {
          .item {
            margin: 13px 0;
            display: flex;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;

            .kuai {
              display: block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            .time {
              margin: 0 20px;
              font-size: 20px;
              font-weight: bold;
            }
          }
        }

        >div {
          flex: 1;
        }

      }
    }

    .daili {
      padding-top: 120px;

      .title {
        font-size: 48px;
        font-family: 'Microsoft YaHei';
        font-weight: bold;
        color: #E31616;
      }

      .mes {
        font-size: 22px;
        font-family: Arial;
        font-weight: 400;
      }

      .kuai {
        width: 80px;
        height: 6px;
        background-color: #E31616;
        margin-top: 10px;
      }

      .coBox {
        margin-top: 51px;
        display: flex;
        height: 820px;

        img {
          width: 100%;
          height: 100%;
        }

        .left {
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;

          >div {
            height: 49%;
          }
        }

        .con {
          flex: 3;
          box-sizing: border-box;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;

          .top {
            height: 66%;
          }

          .bom {
            height: 33%;
          }
        }

        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;

          >div {
            height: 49%;
          }
        }
      }
    }
  }

  .end {
    margin-top: 70px;
    box-sizing: border-box;
    width: 100%;
    height: 190px;
    background-image: url('../assets/组394@2x.png');
    padding: 0 10%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    div {
      width: 100%;
      margin: 5px 0;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
  }
}</style>
